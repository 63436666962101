import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DEFAULT_DATA_RETENTION_DAYS } from '@app-core/constants/constants';
import { ClientConfigResponse } from '@app-core/models/core.model';
import { DurationRangeService } from '@app-core/services/duration-range/duration-range.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientConfigResolver implements Resolve<ClientConfigResponse> {
  constructor(private durationService: DurationRangeService) {}

  public resolve(): Observable<ClientConfigResponse> {
    return this.durationService.getClientConfig().pipe(
      tap((res: ClientConfigResponse) => {
        const { dataRetention = DEFAULT_DATA_RETENTION_DAYS, includePolylinePathInfo = false } = res?.data || {};
        this.durationService.dataRetentionDays = dataRetention;
        this.durationService.includePolylinePathInfo = includePolylinePathInfo;
      }),
      catchError(() => {
        this.durationService.dataRetentionDays = DEFAULT_DATA_RETENTION_DAYS;
        this.durationService.includePolylinePathInfo = false;
        return of(null);
      })
    );
  }
}
