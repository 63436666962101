import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '@app-core/services/data/data.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DriverManagementService } from '@app-driver-management/services/driver-management.service';
@Component({
  selector: 'app-driver-auto-complete',
  templateUrl: './driver-auto-complete.component.html',
  styleUrls: ['./driver-auto-complete.component.scss'],
})
export class DriverAutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public hideAllDrivers: boolean = false;
  @Input()
  public placeholder = 'Search';
  @Input()
  public enableAutocomplete = false;
  @Input()
  public hint = '';
  @Input()
  public inputValue = '';
  @Input()
  public isRequired = false;
  @Input()
  public requiredErrorMessage = '';
  @Input() public includeRegisteredDrivers: boolean = false;
  @Input()
  public includeCoachedDriversOnly: boolean = false;

  @Output()
  private driverSelection = new EventEmitter<string>();
  public inputControl = new FormControl(<any>{});
  public filteredDrivers = [];
  public showSpinner = false;
  public disabled = false;
  public ALL_DRIVERS = { key: 'All Drivers', translateValue: 'All Drivers', passed: false };

  private selectedDriverId = '';
  private ngUnSubscribe: Subject<void> = new Subject();

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private driverManagementService: DriverManagementService
  ) {}

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        takeUntil(this.ngUnSubscribe),
        debounceTime(500),
        map((val: any) => {
          // if input is typed then val is string. else if option is selected then val is object
          const driverId = typeof val === 'string' ? val : val?.driverId;
          return (driverId || '').trim();
        }),
        filter((val) => val !== this.ALL_DRIVERS.key),
        switchMap((searchString) => {
          return this.autoCompleteDriverSearch(searchString);
        })
      )
      .subscribe(({ autoCompleteDrivers, registeredDrivers }) => {
        this.setFilteredDrivers(autoCompleteDrivers, registeredDrivers);
      });
  }

  public ngOnChanges() {
    // on change called before init and "All Drivers" is passed from parent

    if (this.inputValue === this.ALL_DRIVERS.key) {
      this.ALL_DRIVERS.passed = true;
    }

    if (this.inputValue !== '' && this.filteredDrivers.length === 0 && this.inputValue !== this.ALL_DRIVERS.key) {
      // called when the component gets re-rendered with the old parent value
      // API is called to ensure that updated driver name is displayed (UI will store stale values for driver name)
      const searchString = this.inputValue;
      this.autoCompleteDriverSearch(searchString)
        .pipe(takeUntil(this.ngUnSubscribe))
        .subscribe(({ autoCompleteDrivers, registeredDrivers }) => {
          this.setFilteredDrivers(autoCompleteDrivers, registeredDrivers);
          this.updateInputControl();
        });
    } else {
      // handle the rest of input change
      this.updateInputControl();
    }
  }

  public ngOnDestroy() {
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
  }

  public setFilteredDrivers(autoCompleteDrivers: any[], registeredDrivers: any[]) {
    this.filteredDrivers =
      this.includeRegisteredDrivers && registeredDrivers.length
        ? this.removeDuplicates([...autoCompleteDrivers, ...registeredDrivers])
        : autoCompleteDrivers;
  }

  public updateInputControl() {
    // set the input control with driver name and id using the filtered drivers list
    this.inputControl.setValue(this.filteredDrivers.find((el) => el.driverId === this.inputValue) || this.inputValue);
  }

  public displayfn = (option: any) => {
    if (option.driverId !== this.ALL_DRIVERS.key && option !== this.ALL_DRIVERS.key) {
      // handle 'All Drivers' option coming from parent component
      return option?.driverName ? `${option?.driverName} (${option?.driverId})` : option?.driverId || option;
      // return this.translate.instant(this.ALL_DRIVERS.key);
    } else {
      return this.translate.instant(this.ALL_DRIVERS.key);
    }
  };

  private autoCompleteDrivers(driverId = ''): Observable<string[]> {
    const selecteddriverIdInLowerCase = this.selectedDriverId && this.selectedDriverId.toLowerCase();
    if (!driverId || selecteddriverIdInLowerCase === driverId) {
      this.showSpinner = false;
      return of([]);
    }
    const params = {
      search: driverId,
    };

    if (this.includeCoachedDriversOnly) {
      params['includeCoachedDriversOnly'] = true;
    }
    this.showSpinner = true;
    return this.dataService.driversAutocomplete(params).pipe(
      tap(() => (this.showSpinner = false)),
      map((res: any) => {
        const { drivers = [] } = res.data || {};
        return drivers;
      }),
      catchError(() => {
        this.showSpinner = false;
        return of([]);
      })
    );
  }

  public autoCompleteOptionSelected(event: any) {
    const value = event.option.value;
    if (value) {
      this.selectedDriverId = value.driverId;
      this.driverSelection.emit(value === this.ALL_DRIVERS.key ? this.ALL_DRIVERS.key : value.driverId);
    }
  }

  public clearInput() {
    this.inputControl.patchValue('');
    this.driverSelection.emit('');
    this.filteredDrivers.length = 0;
  }

  public autoCompleteDriverSearch(searchString) {
    const autoCompleteDrivers$ = this.autoCompleteDrivers(searchString);
    const registeredDrivers$ = this.includeRegisteredDrivers ? this.getRegisteredDrivers(searchString) : of([]);

    return forkJoin({ autoCompleteDrivers: autoCompleteDrivers$, registeredDrivers: registeredDrivers$ }).pipe(
      catchError(() => of({ autoCompleteDrivers: [], registeredDrivers: [] })),
      takeUntil(this.ngUnSubscribe)
    );
  }

  public getRegisteredDrivers(searchString = '', isRefresh?: boolean): Observable<string[]> {
    this.showSpinner = true;
    const selecteddriverIdInLowerCase = this.selectedDriverId && this.selectedDriverId.toLowerCase();
    if (!searchString || selecteddriverIdInLowerCase === searchString) {
      this.showSpinner = false;
      return of([]);
    }

    return this.driverManagementService.getRegisteredDriverList(isRefresh).pipe(
      finalize(() => {
        this.showSpinner = false;
      }),
      map((res) => {
        const registeredDrivers = res?.data || [];
        return registeredDrivers
          .filter((driver) => driver.userType?.toLowerCase() === 'driver')
          .filter(
            (driver) => driver.driverId?.toLowerCase().includes(searchString) || driver.driverName?.toLowerCase().includes(searchString)
          )
          .map((driver) => ({
            driverId: driver.driverId,
            driverName: driver.driverName,
          }));
      }),
      catchError(() => {
        return of([]);
      }),
      takeUntil(this.ngUnSubscribe)
    );
  }

  private removeDuplicates(allDrivers: any[]) {
    if (allDrivers?.length) {
      const filteredDrivers = allDrivers.reduce((acc, curDriver) => {
        const isDup = acc.some((driver) => driver.driverId === curDriver.driverId);
        if (!isDup) {
          acc.push({
            driverId: curDriver.driverId,
            driverName: curDriver.driverName,
          });
        }
        return acc;
      }, []);

      return filteredDrivers;
    }
  }
}
